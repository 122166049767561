import React from 'react';
import uniq from 'lodash/uniq';

import InputTextField from '../../../inputfields/InputTextField';
import { isNonFoodProduct } from 'utils/products';
import IProduct from '../../../../models/product';
import ThreeWaySwitch from 'components/inputfields/ThreeWaySwitch';

export const getItems = (state: IProduct) => {
  const isFood = !isNonFoodProduct(state);
  let items: string[] = [];
  if (state?.search_terms && state?.search_terms?.trim() !== '') {
    items = state.search_terms.split(',');
  }
  if (state?.title && state?.title?.trim() !== '') {
    const product = state.title.trim().toLowerCase();
    items.push(product);
  }
  if (state?.producerName && state?.producerName?.trim() !== '') {
    const producer = state?.producerName?.trim().toLowerCase();
    items.push(producer);
  }
  if (isFood) {
    if (state?.brand_food && state?.brand_food?.trim() !== '') {
      const brand = state.brand_food.trim().toLowerCase();
      items.push(brand);
    }
  } else {
    if (state?.brand && state?.brand?.trim() !== '') {
      const brand = state.brand.trim().toLowerCase();
      items.push(brand);
    }
  }
  items = items.map((s) => s.trim()).filter((s) => s && s.trim() !== '');
  items = uniq(items.map(search => search.toLowerCase()))
  items = uniq(items);
  return items.join(', ');
};

interface ISearchTermsProps {
  approve: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  userRole?: string;
}

const SearchTerms = ({ approve, state, setState }: ISearchTermsProps) => {

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      search_terms: target.value || getItems({ ...state, search_terms: '' }),
    });
  };

  return (
    <div style={{ display: 'flex', flex: 1, alignItems: 'end' }}>
      <div style={{ flexGrow: 1 }}>
        <InputTextField<IProduct>
          label="Sökbegrepp och nyckelord"
          dense={false}
          inputName="search_terms"
          value={state.search_terms}
          onChange={handleChange}
          withLabel
          multiline={true}
          multilineRows={2}
          multilineMax={20}
          labelOptions={{
            heading: 'Sökbegrepp och nyckelord',
            text: 'Här listas ord eller fraser som konsumenten kan tänkas använda för att söka fram produkter online. Sökbegrepp och nyckelord skrivs med kommatecken mellan orden och utan mellanslag.'
          }}
        />
      </div>
      {approve && state?.approve?.find(x => x.field === 'search_terms') && (
        <ThreeWaySwitch
          name='search_terms'
          state={state}
          setState={setState}
          style={{ height: 56, marginBottom: 16 }}
        />
      )}
    </div>
  );
};

export default SearchTerms;
