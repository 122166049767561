import { FC, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { path } from 'ramda';
import axios from 'axios';

import { Box, CircularProgress } from '@material-ui/core';

import { restEndpoints } from 'constants/domain';
import { tiffToPng } from 'components/fileuploader/ImageUploader2';
import { KeycloakContext } from 'components/Secured';
import { mapChatGptDataToFill, removeNilProperty } from 'components/vision/tabs/chatGPT';
import InputLabel from 'components/inputfields/InputLabel';
import IProduct, { IFoodlaCategory, IRecognizeImage } from 'models/product';
import { useMutation } from '@apollo/react-hooks';
import { S3_SIGNED_REQUEST_MUTATION } from 'graphql/mutations';
import { encodeLastPathUrl } from 'utils/imgix';
import { getImageWithSizeSuffix, IMAGE_SIZES } from 'utils/images2';
import { isBlank } from 'utils/helpers-ts';

export const ALLOWED_IMAGE_FILE_TYPE_LIST = ['image/jpeg', 'image/jpg', 'image/tiff', 'image/png'];

interface FillProductByImageProps {
  isFood?: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
}

const grid = 4;

const getImageStyle = (itemIndex: number): React.CSSProperties => ({
  display: 'inline-flex',
  borderRadius: 2,
  //width: 120,
  height: 120,
  boxSizing: 'border-box',
  cursor: 'pointer',
});

const getListStyle = (isDraggingOver: boolean) => ({
  marginTop: 20,
  marginBottom: 20,
  background: isDraggingOver ? 'rgba(173, 213, 255, 0.5)' : 'lightgrey',
  borderRadius: 3,
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

interface FileWithPreview extends File {
  path?: string;
  preview?: string;
}

const getItemStyle = (
): React.CSSProperties => ({
  maxHeight: 400,
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  borderRadius: 2,
  display: 'flex',
  border: 'none',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: "white",
});

type TSignedRequest = {
  signedRequest: string;
  url: string;
  pictureUrl: string;
  picturePath: string;
  fileName: string;
};

type TSignedRequestResponse = {
  getS3SignRequest: {
    requests: TSignedRequest[];
  };
};

const API_TIMEOUT = 60 * 1000;

const FillProductByImage: FC<FillProductByImageProps> = ({ isFood, state, setState }) => {
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx) as string;

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [images, setImages] = useState<IRecognizeImage[]>(state.recognizeImages || []);
  const [imageUrl, setImageUrl] = useState<string|undefined>(undefined);

  const handleSendImageToChatGpt = async (file: File) => {
    let image: FileWithPreview | undefined;
    // setIsError(false);
    // setLoading(true);
    try {
      image = await tiffToPng(file, token);
      if (!image) {
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('files', image);

      const { data } = await axios.post<{ data: Record<string, any> }>(restEndpoints.aiImage, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('chatGPT', data.data);
      return data.data;
    } catch (error: any) {
      return;
    } finally {
      setLoading(false);
    }
  };

  const [categoryData, setCategoryData] = useState<any>({});

  useEffect(() => {
    const hasCategory = !isBlank(state?.foodlaCategory?.id);
    if (imageUrl && !hasCategory) {
      const formData = new FormData();
      formData.append('imageUrl', imageUrl);
      axios.post<{ data: Record<string, any> }>(restEndpoints.aiCategory, formData, {
        headers: { Authorization: `Bearer ${token}` },
      }).then(({ data }) => {
        // console.log('!!!categoryData', data);
        const categoryData = data?.data;
        setCategoryData(categoryData);
      });
    }
  }, [imageUrl]);

  useEffect(() => {
    if (categoryData) {
      if (!isBlank(categoryData?.categoryID) && !isBlank(categoryData?.categoryName)) {
        const category: IFoodlaCategory = {
          id: categoryData?.categoryID,
          name: categoryData?.categoryName,
        };
        setState({ ...state, foodlaCategory: category });
      }
      console.log('!!!categoryData', categoryData);
    }
  }, [categoryData]);
  
  const [s3SignedRequest] = useMutation<TSignedRequestResponse>(S3_SIGNED_REQUEST_MUTATION, { refetchQueries: [] });
  const producerName = state?.producerUsername || state?.producerName || '';
  const { getRootProps, getInputProps } = useDropzone({
    accept: ALLOWED_IMAGE_FILE_TYPE_LIST.join(', '),
    multiple: false,
    disabled: loading,
    onDrop: async (acceptedFiles: File[]) => {
      setIsError(false);
      setLoading(true);
      const file = acceptedFiles[0];
      if (!file) return;

      console.log('!!!file', file);
      const input = {
        ean: state.EAN || '',
        producerName: producerName,
        files: [{ fileName: file.name, fileType: file.type }],
        renameFiles: true,
      };
      console.log('input', input);
      console.log('state', state);
      const { data, errors } = await s3SignedRequest({
        variables: {
          input,
        },
      });
      if (!errors) {
        console.log('!!!');
        console.log(data);
        const signedRequest = data?.getS3SignRequest?.requests?.[0];
        console.log('!!!signedRequest', signedRequest);

        if (signedRequest) {
          const signedResult = await axios.put(signedRequest.signedRequest, file, {
            timeout: API_TIMEOUT,
            headers: { 'Content-Type': file.type },
          });
          console.log('!!!signedResult', signedResult);
          const url = encodeLastPathUrl(signedRequest.pictureUrl);
          console.log('!!!url', url);
          setImageUrl(url);
          // uploadedImageData.url = encodeLastPathUrl(signedRequest.pictureUrl);
          if (url) {
            const oldImages = images || [];
            const image: IRecognizeImage = {
              url,
              name: file.name,
            };
            oldImages.push(image);
            setImages(oldImages);
          }
        }
      } else {
        console.log('!!!');
        console.error(errors);
      }


      // if (imageUrl) {
      //   const formData = new FormData();
      //   formData.append('imageUrl', imageUrl);
      //   const { data: categoryData } = await axios.post<{ data: Record<string, any> }>(restEndpoints.aiCategory, formData, {
      //     headers: { Authorization: `Bearer ${token}` },
      //   });
      //   console.log('!!!categoryData', categoryData);
      // }

      const chatGptData = await handleSendImageToChatGpt(file as File);

      if (chatGptData?.recognizeData) {
        let updatingState = mapChatGptDataToFill(state, chatGptData, { isFood });
        updatingState = removeNilProperty(updatingState);
        setState({ ...state, ...updatingState });
        setIsError(false);
      } else {
        setIsError(true);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    return () => {
      if (setState) {
        setState({ ...state, recognizeImages: images });
      }
    };
  }, [images]);
  return (
    <Box my={2}>
      <InputLabel heading="Bild på produktinformation" />
      <p style={{backgroundColor: '#F5F5F5'}}>
        Ladda upp en bild med produktinformation. t. ex. baksidan av produkt, en skärmdump eller etikett. Foodla autofyller fält, kategoriserar och ger förslag på en bra produktbeskrivning!
      </p>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {loading ? (
          <>
            <CircularProgress size={30} />
            <span>Reading image... this might take a while</span>
          </>
        ) : (
          <span>Dra en bild till det här området eller klicka för att välja en från din dator</span>
        )}
      </div>

      {/* <aside style={thumbsContainer}>{thumbsPictures}</aside> */}
      <div style={getListStyle(false)}>
        {images.map((image, index) => {
          // if (image && !file.preview) {
          //   file.preview = URL.createObjectURL(file);
          // }
          const bigImage = image.url ? getImageWithSizeSuffix(image.url, IMAGE_SIZES.LARGE) : '';
          return (
            <div
              style={getItemStyle()}
            >
                <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                <img
                      // imgSrc: getImageWithSizeSuffix(i, IMAGE_SIZES.SMALL),

                  src={image.url ? getImageWithSizeSuffix(image.url, IMAGE_SIZES.SMALL) : ''}
                  style={getImageStyle(index)}
                  alt="Not available"
                  onClick={() => {
                    const newTab = window.open();
                    if (newTab) {
                      newTab.document.body.innerHTML = `<img src="${bigImage}" style="width: 100%;" alt="Full Image"/>`;
                    }
                  }}
                />
                </div>
              <b>{image.name}</b>
            </div>
          );
        })}
      </div>

      {isError && (
        <Box mt={1} color="orangered" fontWeight={700}>
          Det verkar inte som om att vi kunde hitta produktinformationen på bilden du laddade upp. Testa gärna ladda upp
          en bild där produktinformationen är synlig så gör vi ett försök till!
        </Box>
      )}
    </Box>
  );
};

export default FillProductByImage;
