import { FC, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { path } from 'ramda';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import IProduct, { NutritionalUnit } from 'models/product';
import { restEndpoints } from 'constants/domain';
import RetryIcon from '@material-ui/icons/Replay';

import { isBlank } from 'utils/helpers-ts';
import { Button, Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import { KeycloakContext } from 'components/Secured';
import { IImage } from 'components/images/ImageSorter';

import ChatGptLogo from '../../../assets/chatgpt-logo.png';
import VisionTab from '../tab';

const fieldNameMap: Record<string, string> = {
  title: 'Produktnamn',
  EAN: 'EAN-kod',
  short_text: 'Produktbeskrivning',
  ingredient_statement: 'Ingrediensförteckning',
  non_food_ingredients: 'Innehållsförteckning',
  animalFoodIngredients: 'Fodersammansättning',
  nutritional_unit: 'Nutrtional unit',
  energi_kj: 'Energi kj',
  energi_kcal: 'Calories',
  fett: 'Fett',
  mattatFett: 'Varav mättat fett',
  kolhydrat: 'Kolhydrat',
  sockerarter: 'Varav sockerarter',
  fiber: 'Fiber',
  protein: 'Protein',
  salt: 'Salt',
  descriptive_size_amount: 'Nettokvantitet (mängd)',
  descriptive_size_unit: 'Nettokvantitet enhet',
  minTemperature: 'Förvaringstemperatur min',
  maxTemperature: 'Förvaringstemperatur max',
  gross_weight_num: 'Bruttovikt (in grams)',
  place_of_item_activity: 'Ursprungsland',
  brand_food: 'Varumärke',
  brand: 'Varumärke',
};

// graphql query will return "null", although fields did not exist in database
export const removeNilProperty = (data?: Record<string, any>) => {
  const result: Record<string, any> = {};

  Object.entries(data || {}).forEach(([key, val]) => {
    if (!isNil(val) && val !== '') result[key] = val;
  });

  return result;
};

export const mapChatGptDataToFill = (state: IProduct, data: Record<string, any>, { isFood }: { isFood?: boolean }) => {
  const overWriteStringValue = (stateValue?: string, gptValue?: string): string | undefined => {
    if (isBlank(stateValue)) return gptValue;
    return stateValue;
  };

  const overWriteNumberValue = (
    stateValue?: number,
    gptValue?: number,
    options?: { checkZero?: boolean }
  ): number | undefined => {
    if (isNil(stateValue)) return gptValue;
    if (options?.checkZero) {
      if (stateValue === 0) return gptValue;
    }
    return stateValue;
  };

  let updatingState: Partial<IProduct> = {
    title: overWriteStringValue(state.title, data.title),
    EAN: overWriteStringValue(state.EAN, data.EAN),
    short_text: overWriteStringValue(state.short_text, data.short_text),
    ingredient_statement: overWriteStringValue(state.ingredient_statement, data.ingredient_statement),
    non_food_ingredients: overWriteStringValue(state.non_food_ingredients, data.non_food_ingredients),
    animalFoodIngredients: overWriteStringValue(state.animalFoodIngredients, data.animalFoodIngredients),
    energi_kj: overWriteNumberValue(state.energi_kj, data.energi_kj),
    energi_kcal: overWriteNumberValue(state.energi_kcal, data.energi_kcal),
    fett: overWriteNumberValue(state.fett, data.fett),
    mattatFett: overWriteNumberValue(state.mattatFett, data.mattatFett),
    kolhydrat: overWriteNumberValue(state.kolhydrat, data.kolhydrat),
    sockerarter: overWriteNumberValue(state.sockerarter, data.sockerarter),
    fiber: overWriteNumberValue(state.fiber, data.fiber),
    protein: overWriteNumberValue(state.protein, data.protein),
    salt: overWriteNumberValue(state.salt, data.salt),
    descriptive_size_amount: overWriteNumberValue(state.descriptive_size_amount, data.descriptive_size_amount, {
      checkZero: true,
    }),
    descriptive_size_unit: overWriteStringValue(state.descriptive_size_unit, data.descriptive_size_unit),
    min_temperature: overWriteNumberValue(state.min_temperature, data.minTemperature),
    max_temperature: overWriteNumberValue(state.max_temperature, data.maxTemperature),
    gross_weight_num: overWriteNumberValue(state.gross_weight_num, data.gross_weight_num),
    place_of_item_activity: overWriteStringValue(state.place_of_item_activity, data.place_of_item_activity),
  };

  if (isBlank(state.nutritional_unit) && !isBlank(data.nutritional_unit)) {
    if ([NutritionalUnit.GRAM, NutritionalUnit.MILLILITER].includes(data.nutritional_unit)) {
      updatingState.nutritional_unit = data.nutritional_unit;
    }
  }

  if (isFood) {
    if (isBlank(state.brand_food)) {
      updatingState.brand_food = data.brand_food || data.brand;
    }
  } else {
    if (isBlank(state.brand)) {
      updatingState.brand = data.brand || data.brand_food;
    }
  }

  return removeNilProperty(updatingState);
};

interface ChatGPTProps {
  isNew: boolean;
  isFood?: boolean;
  visionTab: string;
  image?: IImage;
  imageData?: IImage;
  info?: {
    chatGPT?: Record<string, string>;
  };
  state: IProduct;
  setState: (state: IProduct) => void;
}

const ChatGPT: FC<ChatGPTProps> = ({ visionTab, isNew, isFood, image, imageData, info, state, setState }) => {
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record<string, any>>({});
  const [selectedFields, setSelectedFields] = useState<Record<string, boolean>>({});

  const handleSendImageToChatGpt = async () => {
    if (!imageData) return;

    const formData = new FormData();

    try {
      if (isNew) {
        const imageFile = await fetch(imageData.imgSrc || '').then(response => response.blob());
        formData.append('files', imageFile);
      } else {
        formData.append('imageUrl', imageData.pictureUrl || '');
      }

      setLoading(true);
      const { data } = await axios.post<{ data: Record<string, any> }>(restEndpoints.aiImage, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('response', data.data);
      setData(removeNilProperty(data.data));
      console.log('chatGPT', data);
      return data;
    } catch (error: any) {
      return { error: error.message };
    } finally {
      setLoading(false);
    }
  };

  const handleFillData = () => {
    let updatingState = mapChatGptDataToFill(state, data, { isFood });

    Object.entries(selectedFields).forEach(([field, checked]) => {
      if (!checked) {
        delete updatingState[field as keyof IProduct];
      }
    });

    updatingState = removeNilProperty(updatingState);

    setState({ ...state, ...updatingState });
  };

  useEffect(() => {
    setData(removeNilProperty(info?.chatGPT));
  }, [info]);

  useEffect(() => {
    const newSelectedData: Record<string, boolean> = {};
    Object.keys(data).forEach(field => {
      newSelectedData[field] = true;
    });
    setSelectedFields(newSelectedData);
  }, [data]);

  useEffect(() => {
    console.log('ChatGPT', data);
  }, [data]);

  return (
    <VisionTab
      index="chatGPT"
      tabValue={visionTab}
      image={image}
      pictureWidth={6}
      info={info}
      isNew={isNew}
      hideImage={false}
      vertices={undefined}
      showLabels={undefined}
      normalizedVertices={undefined}
    >
      {!!imageData && (
        <Button disabled={loading} color="primary" variant="contained" size="small" onClick={handleSendImageToChatGpt}>
          {loading ? (
            <CircularProgress size={24} style={{ display: 'block' }} />
          ) : isEmpty(data) ? (
            <img src={ChatGptLogo} alt="chat-gpt-logo" style={{ width: 24 }} />
          ) : (
            <RetryIcon />
          )}

          {isEmpty(data) && <span style={{ marginLeft: 8 }}>Send image to chatGPT</span>}
        </Button>
      )}

      {!isEmpty(data) && !data.recognizeData && (
        <p style={{ color: 'red', fontWeight: 'bold' }}>Unable to detect information on image.</p>
      )}

      {!isEmpty(data) && !!data.recognizeData && (
        <Button
          disabled={loading}
          color="primary"
          variant="outlined"
          size="small"
          onClick={handleFillData}
          style={{ marginLeft: 16 }}
        >
          Autofill information
        </Button>
      )}

      {!isEmpty(data) && !!data.recognizeData && (
        <div style={{ maxWidth: '100%', overflowX: 'auto', marginTop: 8 }}>
          {Object.entries(data || {}).map(([field, value]) => {
            if (field === 'recognizeData') return null;

            return (
              <div key={field}>
                <FormControlLabel
                  label={
                    <p style={{ margin: 0, marginTop: 8 }}>
                      <span style={{ marginRight: 4 }}>
                        <b>{fieldNameMap[field] || ''}:</b>
                      </span>
                      <span>{value}</span>
                    </p>
                  }
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={!!selectedFields[field]}
                      onChange={event => setSelectedFields({ ...selectedFields, [field]: event.target.checked })}
                    />
                  }
                  style={{ alignItems: 'start' }}
                />
              </div>
            );
          })}
        </div>
      )}
    </VisionTab>
  );
};

export default ChatGPT;
